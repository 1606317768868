import '../static/style/sectionStyle.css'
import { useEffect, useState } from 'react'
import { useIntersection } from '../customHooks/UseIntersection'

export const Home = ({ secOneRef, selectNewSection, detectScroll }) => {
  const visible = useIntersection(secOneRef)
  const [homeTextClass, setHomeTextClass] = useState("")
  const [outerButtonClass, setOuterButtonClass] = useState("") 

  useEffect(() => {
    if (visible) {
      detectScroll(secOneRef)
      setHomeTextClass("home-text active-scroll-from-left")
      setOuterButtonClass("home-btn-div-outer active-scroll-from-bottom")
    }else{
      setHomeTextClass("hidden")
      setOuterButtonClass("hidden")
    }
      
    console.log("Hello");
  }, [visible, detectScroll, secOneRef])

  const handleClick = (section) => {

    selectNewSection("sec-2")
  }
  return (
    <div id="sec-1" ref={secOneRef} className="default first item ">

      <p className={homeTextClass}>
        Hello, my name is Jim Lotruglio and I'm a Software Engineer
      </p>
      {/* <button className='home-btn' onClick={handleClick}><h4>View My Portfolio</h4></button> */}
      <div className={outerButtonClass}>
        <div className="home-btn-div-inner" onClick={handleClick}>
          View Profile
        </div>
      </div>
    </div>
  )
}