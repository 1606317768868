import { useEffect, useState } from 'react'
import { useIntersection } from '../customHooks/UseIntersection'
import '../static/style/sectionStyle.css'
import CodeTechCollageBackground from '../static/images/codeTechLogosTwo.jpeg'


export const AboutMe = ({ secTwoRef, detectScroll }) => {
    const visible = useIntersection(secTwoRef)
    const [aboutRightClass, setAboutRightClass] = useState("")
    const [aboutLeftClass, setAboutLeftClass] = useState("")

    useEffect(() => {
        if (visible){
            detectScroll(secTwoRef)
            setAboutRightClass("about-right active-scroll-from-right")
            setAboutLeftClass("about-left active-scroll-from-left")
        }else{
            setAboutRightClass("hidden")
            setAboutLeftClass("hidden")
        } 

    }, [visible, detectScroll, secTwoRef])

    return (
        <div id="sec-2" ref={secTwoRef} className="item about-sec">
            
            <div className={aboutLeftClass}>
            {/* <a className="resume-link" href={resume} rel="noreferrer" target="_blank">
                View My Resume
                </a> */}

                <p className='about-text'>
                After earning my associate’s in liberal arts in 2010, I switched majors several times 
                before deciding to take some time off to figure out what I really wanted to do with 
                my life. In 2017 a software engineer friend of mine encouraged me to give programming a 
                try. Soon after this, I completed the Flatiron School Coding Bootcamp before enrolling 
                back into college to earn my bachelor’s in computer science. I finally graduated from Weber 
                State University in August 2024 and then moved back to my home state of New Jersey. I 
                loved coding and building software and web applications right from the start and I’m 
                excited for this next chapter in my life. Outside of my professional life, I like playing 
                guitar, reading and meditating by the beach.
                </p>
                
            </div>
            <div className={aboutRightClass}>

                <img src={CodeTechCollageBackground} alt="" className='code-image'/>
                {/* <img src={python} alt="" className='code-image'/> */}
               
            </div>
            {/* <h2>About Me</h2>
            <p>Lorem ipsulm dolor sit amet rem adipisci delectus? consectetur adipisicing elit. Ipsam cum, voluptate vero,
                blanditiis voluptatum aut consectetur et in quam rem adipisci delectus enim quod a laudantium perferendis ad
                possimus culpa?</p> */}
        </div>
    )
}