import { useEffect, useState } from "react"

export const useIntersection = (el) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const current = el?.current
        const observer = new IntersectionObserver(
            ([entry]) => {
                setVisible(entry.isIntersecting)
            },
            {threshold: .5}
        )
        current && observer?.observe(current)

        return () => current && observer.unobserve(current)
    }, [el])

    return visible
}