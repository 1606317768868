import '../static/style/sectionStyle.css'
import { useIntersection } from '../customHooks/UseIntersection'
import { useEffect } from 'react'
import wordlyPreview from '../static/images/wordlyPreview.jpg'


export const Projects = ({ secThreeRef, detectScroll }) => {
  const visible = useIntersection(secThreeRef)

  useEffect(() => {
    if (visible) detectScroll(secThreeRef)

  }, [visible, detectScroll, secThreeRef])

  return (
    <div id="sec-3" ref={secThreeRef} className="item">
      <h1 className='projects-title'>Personal Projects</h1>
      <div className='project-div'>
        <a href="https://wordlyapp.netlify.app/" className='app-link' rel="noreferrer" target="_blank">
          <img src={wordlyPreview} className='app-image' alt="" height="400" width="275" />
          <h2 className='app-title'>Wordly</h2>
        </a>
      </div>

    </div>
  )
}