import { Navbar } from './components/Navbar';
import { Home } from './sections/Home';
import { AboutMe } from './sections/AboutMe';
import { Projects } from './sections/Projects';
import { Contact } from './sections/Contact';
import { useRef, useState } from 'react';


function App() {
  const secOneRef = useRef()
  const secTwoRef = useRef()
  const secThreeRef = useRef()
  const secFourRef = useRef()
  const [currentSection, setCurrentSection] = useState("sec-1")

  const selectNewSection = (section) => {
    switch(section){
      case "sec-1":
        secOneRef.current.scrollIntoView({ behavior: 'smooth' })
        break
      case "sec-2":
        secTwoRef.current.scrollIntoView({ behavior: 'smooth'})
        break
      case "sec-3":
        secThreeRef.current.scrollIntoView({ behavior: 'smooth'})
        break
      case "sec-4":
        secFourRef.current.scrollIntoView({ behavior: 'smooth'})
        break
      default:
        break
    }
  }


  const detectScroll = (el) => {
    console.log(el.current.id);
    setCurrentSection(el.current.id)
  }

  return (
    <div className="App">
      <Home secOneRef={secOneRef} selectNewSection={selectNewSection} detectScroll={detectScroll}/>
      <Navbar selectNewSection={selectNewSection} currentSection={currentSection}/>
      
      <AboutMe secTwoRef={secTwoRef} detectScroll={detectScroll}/>
      <Projects secThreeRef={secThreeRef} detectScroll={detectScroll}/>
      <Contact secFourRef={secFourRef} detectScroll={detectScroll}/>

      <footer className='footer'>
      <a href="https://www.flaticon.com/free-icons/email" title="email icons">Email icons created by Freepik - Flaticon</a> <br />
      <a href="https://www.flaticon.com/free-icons/linkedin" title="linkedin icons">Linkedin icons created by Freepik - Flaticon</a> <br />
      <a href="https://www.flaticon.com/free-icons/github" title="github icons">Github icons created by iconsmind - Flaticon</a> <br />
      <a href="https://www.flaticon.com/free-icons/git" title="git icons">Git icons created by pocike - Flaticon</a>
      <a target="_blank" rel="noreferrer" href="https://icons8.com/icon/23877/resume">Resume</a> icon by <a target="_blank" rel="noreferrer" href="https://icons8.com">Icons8</a>
      </footer>
    </div>
  );
}

export default App;
