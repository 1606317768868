import { useEffect, useState } from 'react'
import '../static/style/navBarStyle.css'

export const Navbar = ({selectNewSection, currentSection}) => {

    const [homeClass, setHomeClass] = useState("active")
    const [aboutClass, setAboutClass] = useState("nav-btn")
    const [projectsClass, setProjectsClass] = useState("nav-btn")
    const [contactClass, setContactClass] = useState("nav-btn")
    const chooseSection = (e) => {
        const selectedId = e.target.id
        selectNewSection(selectedId)
    }

    const deactivateAll = () => {
        setHomeClass("nav-btn")
        setAboutClass("nav-btn")
        setProjectsClass("nav-btn")
        setContactClass("nav-btn")
    }

    useEffect(() => {
        deactivateAll()

        switch(currentSection){
            case "sec-2":
                setAboutClass("active")
                break 
            case "sec-3":
                setProjectsClass("active")
                break 
            case "sec-4":
                setContactClass("active")
                break 
            default: 
                setHomeClass("active")
                break
        }

    }, [currentSection])

    return (
        <nav>
            <div className="container">
                <ul>
                    <li className='btn-li'><button id="sec-1" className={homeClass} onClick={chooseSection}>Home</button></li>
                    <li className='btn-li'><button id="sec-2" className={aboutClass}  onClick={chooseSection}>About</button></li>
                    <li className='btn-li'><button id="sec-3" className={projectsClass} onClick={chooseSection} >Projects</button></li>
                    <li className='btn-li'><button id="sec-4" className={contactClass} onClick={chooseSection}>Contact</button></li>
                    {/* <li className='btn-li'> */}
                        {/* <button id="" className='nav-btn' > */}
                                    {/* <button className="nav-btn" href={resume} rel="noreferrer" target="_blank">
                                        Resume</button> */}
                        
                        {/* </button> */}
                        {/* </li> */}
                </ul>
            </div>
        </nav>
    )
}